@import url("https://fonts.googleapis.com/css2?family=Caveat:wght@400;500&family=Montserrat:wght@100;200;400;700&display=swap");

/* General Layouts */

/* CSS zum Ausblenden der Navigationsspalten in der Portrait-Ansicht auf Smartphones */
@media (max-width: 820px) {

  .left-nav,
  .right-nav {
    display: none;
  }
}



.videoframe {
  max-width: 100%;
}

.header {
  text-align: center;
}

.testbox {
  padding: 30px;
}

.office-stripe {
  background-color: rgba(8, 66, 152, 0.7);
  padding: 10px 0;
  color: white;
}

.section-head {
  color: rgb(8, 66, 152);
  padding: 0 10px;
  font-size: 2.3em;
}

.dark {
  color: rgb(30, 44, 66);
}

.card-body h2 {
  color: rgb(8, 66, 152);
  font-size: 1.5em;
}

.card-subtitle {
  color: rgb(8, 66, 152) !important;
}

.telefon a,
.telefon a:visited {
  color: #fff;
  text-decoration: none;
}

.telefon a:hover {
  color: #ffc107;
}

.navbar-toggler:focus {
  box-shadow: none !important;
}

.navbar-dark.bg-dark {
  /* background-color: rgba(97, 17, 194, 0.8) !important; */
  background-color: rgba(8, 66, 152, 0.9) !important;
}

.footer {
  background-color: #f5f5f5;
}

.footer>.container {
  padding-right: 15px;
  padding-left: 15px;
}

.ct-main {
  min-height: 200px;
  margin-top: 50px;
}

.button-cont {
  display: flex;
  justify-content: center;
  gap: 1em;
}

.jumbotron {
  /* background-image: url(img/schoolbanner.jpeg) top center !important; */
  background: url("/src/img/school-23.jpeg") bottom center no-repeat !important;
  background-size: cover !important;
  min-height: 200px;
  padding: 8rem 0 4rem 0;
  color: #fff;
  margin-bottom: 0 !important;
  text-align: center;
}

.jumbotron h1 {
  text-shadow: 2px 2px 4px #333;
  font-size: 3em;
}

.labertext {
  font-family: "Caveat", cursive;
  font-size: 2em;
  line-height: 1.1em;
  padding-top: 1.1em;
  text-shadow: 2px 2px #333;
}

footer h5 {
  color: aqua;
}

.errorbox {
  margin: 4rem 0 6rem 0;
}

.page {
  margin: 2rem 0 6rem 0;
}